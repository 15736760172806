import React from 'react';
import './css/service.css';
import image1 from "./image/hc1.png";
import image2 from "./image/ed.png";

import image4 from "./image/pu1.png";
import image5 from "./image/re.png";
import image3 from "./image/image9.png";
import image6 from "./image/cust.jpg";
import image7 from "./image/d.jpg";
import image8 from "./image/med.jpg";
import image9 from "./image/s.jpg";
const Services= () => {
    return (
        <div>
          {/* First Div: Main Section */}
          <div className="main-section">
  <div className='main-content'><h1>Welcome to Our Service</h1>
  <p>We provide top-notch services, enhanced by SyncCare’s cutting-edge technology, to cater to your needs and drive exceptional results.</p>
</div>
  </div>

          {/* Second Div: Three Images in a Line */}
          <section className="services-section">
  
  <div className="services-list">
  <div className="service-item">
      <img src={image8} alt="Medical Billing" />
      <div className="services-details">
  <h3>Medical Billing</h3>
  <p>Utilize SyncCare’s advanced technology to streamline and enhance various aspects of your healthcare operations:</p>
  <br/>
  <ul className="services-list1">
    <li>Service Provider Credentialing</li>
    <li>Patient Registration</li>
    <li>Patient Scheduling</li>
    <li>Insurance Eligibility Verification</li>
    <li>Coding & Scrubbing</li>
    <li>Claims Submission</li>
    <li>A/R Follow-Up</li>
    <li>Denials Analysis</li>
    <li>Denials Appeals</li>
    <li>Payment Posting</li>
    <li>Patient Statements Generation</li>
    <li>Regular & Scheduled Reports</li>
    <li>Big Data Generation</li>
    <li>Data Analysis & Reports</li>
    <li>Privacy & Data Security</li>
  </ul>
</div>
</div>
    <div className="service-item">
      <img src={image7} alt="Digital Marketing" />
      <h3>Digital Marketing</h3>
      <p>Utilize SyncCare’s data-driven insights to enhance your marketing strategies and reach your target audience effectively.</p>
      <br/>
   <ul className="services-list1">
    
    <li>Content Creation</li>
    <li>Social Media Marketing</li>
    <li>Search Engine Optimization</li>
  </ul>
    </div>
    <div className="service-item">
      <img src={image9} alt="Software Development" />
      <h3>Software Development</h3>
      <p>Develop customized solutions integrated with SyncCare’s platform to streamline operations and boost efficiency.</p>
      <br/>
   <ul className="services-list1">
    
    <li>Website & Mobile Apps

</li>
    <li>E-commerce Solutions</li>
    <li>Medical Software Solutions</li>
  </ul>
    </div>
   
    <div className="service-item">
      <img src={image6} alt="Customer Support" />
      <h3>Customer Support</h3>
      <p>Enhance customer satisfaction with SyncCare’s support tools, ensuring timely and effective assistance.</p>
   <br/>
   <ul className="services-list1">
    
    <li>Call & Chat Support</li>
    <li>Appointments Scheduling</li>
    <li>Complaint & Query Handling</li>
  </ul>
    </div>
  </div>
</section>
    
<section className="partner-industries-section">
  <h2>Partner Industries</h2>
  <p>We proudly partner with a diverse range of industries to deliver innovative solutions and exceptional service. Our expertise spans across various sectors, allowing us to provide tailored solutions that meet the unique needs of each industry.</p>
  <div className="industries-list">
    <div className="industry-item">
      <img src={image1} alt="Health Care" />
      <h3>Health Care</h3>
      <p>Transforming healthcare operations with advanced solutions.</p>
    </div>
    <div className="industry-item">
      <img src={image5} alt="Retail" />
      <h3>Retail</h3>
      <p>Optimizing retail operations for improved customer experiences.</p>
    </div>
    <div className="industry-item">
      <img src={image2} alt="Education" />
      <h3>Education</h3>
      <p>Empowering educational institutions with advanced tools and support.</p>
    </div>
    <div className="industry-item">
      <img src={image4} alt="Public Sector" />
      <h3>Public Sector</h3>
      <p>Enhancing public sector services with efficient and scalable solutions.</p>
    </div>
  </div>
</section>
<section className="why-choose-us-section">
  <h2>Why Choose Us?</h2>
  <ul className="choose-us-list">
    <li>
      <h3>Expertise and Experience</h3>
      <p>Our team brings years of experience in medical billing and coding, ensuring that your claims are handled by knowledgeable professionals.</p>
    </li>
    <li>
      <h3>Personalized Service</h3>
      <p>We understand that every practice is unique. We work closely with you to develop customized billing solutions that align with your specific needs and goals.</p>
    </li>
    <li>
      <h3>Cutting-Edge Technology</h3>
      <p>We use advanced billing software and tools to streamline processes and enhance accuracy.</p>
    </li>
    <li>
      <h3>Commitment to Compliance</h3>
      <p>We stay updated on industry changes and regulatory requirements to ensure that your billing practices are always in compliance.</p>
    </li>
  </ul>
</section>
        </div>
      );
    };
export default Services;

import React from 'react';
import './css/about.css';
import image1 from "./image/mission.jpg"
const AboutUs = () => {
    return (
        <div className="about-page">
          {/* Hero Section */}
          <section className="abouthero-section">
  <div className="abouthero-content">
    <h1>About Us</h1>
    <p>
    At SyncCare Billing LLC, we simplify medical billing with precision and care. Our mission is to streamline your revenue cycle, ensuring faster payments and fewer denials. Combining industry expertise with cutting-edge technology, we offer personalized billing solutions that empower healthcare providers to focus on patient care while we handle the rest. Trust us to turn your billing challenges into growth opportunities.</p>
  </div>
</section>

    
          {/* Mission Section */}
          <section className="mission-section">
  <div className="mission-content">
    <h1>Our Mission</h1>
    <p>
    Our mission is to simplify the billing process and enhance the financial health of medical practices. We strive to deliver accurate and timely billing services while ensuring compliance with the latest regulations and standards. Our goal is to be a trusted partner that helps you optimize your revenue cycle, reduce administrative burdens, and achieve financial clarity.</p>
  </div>
  <div className="mission-image">
    <img src={image1} alt="Our Mission" />
  </div>
</section>

    
          {/* Team Section */}
          <section className="team-section">
  <h2>Meet the Team</h2>
  <div className="team-members">
    <div className="team-member">
      <h3>David Proctor</h3>
      <p>CEO & Founder</p>
    </div>
    <div className="team-member">
      <h3>Mike Stone</h3>
      <p>CTO</p>
    </div>
    <div className="team-member">
      <h3>Arsalan Ahmed</h3>
      <p>Manager</p>
    </div>
  </div>
</section>

    
          {/* Values Section */}
          <section className="values-section">
  <h2>Our Values</h2>
  <div className="values-list">
    <div className="value-item">
      <h3>Integrity</h3>
      <p>We believe in transparency and ethical practices, strengthened by SyncCare’s commitment to secure and compliant technology solutions.</p>
    </div>
    <div className="value-item">
      <h3>Innovation</h3>
      <p>We strive to be at the forefront of innovation and technology, leveraging SyncCare’s cutting-edge tools to drive progress and create impactful solutions.</p>
    </div>
    <div className="value-item">
      <h3>Customer Focus</h3>
      <p>Our customers are at the center of everything we do, and SyncCare’s advanced support and service features ensure we deliver exceptional experiences.</p>
    </div>
  </div>
</section>

<section className="commitment-section">
  <h2>Our Commitment</h2>
  <p>
    At SyncCare Billing LLC, we are committed to providing exceptional service and building long-term relationships with our clients.
    Your success is our success, and we are here to support you every step of the way.
  </p>
  <p>
    Thank you for considering SyncCare Billing LLC as your trusted billing partner. We look forward to helping your practice thrive.
  </p>
</section>

        </div>
      );
    };

export default AboutUs;

import React from 'react';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import './css/ContactForm.css';
import MyMap from './map';

const ContactUs = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();

 
  const onSubmit = (data, e) => {
    // Send email via EmailJS
    emailjs.send('service_uqkqwh9', 'template_s1e78qo', {
      name: data.name,
      email: data.email,
      message: data.message,
    }, 'qDZeTt0Po96lj8y-v')
      .then((result) => {
        console.log(result.text);
        alert('Form submitted successfully');
      }, (error) => {
        console.log(error.text);
        alert('Failed to send email. Please try again later.');
      });

    e.target.reset(); // Clear the form after submission
  };
  return (
    <div className="contact-page">
      {/* Hero Section */}
      <section className="contact-section">
        <div className='contact-content'>
          <h1>Contact US</h1>
          <p>
            Ready to experience the difference? Get in touch with us today to learn more about how Synccare Billing LLC can optimize your billing processes and enhance your practice’s financial health.
          </p>
        </div>
      </section>

      <br />

      <div className="get-in-touch-container">
        {/* Contact Form Section */}
        <div className="contact-form-container">
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                id="name"
                type="text"
                {...register('name', { required: 'Name is required' })}
                className={`input-field ${errors.name ? 'error-border' : ''}`}
              />
              {errors.name && <p className="error-message">{errors.name.message}</p>}
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Enter a valid email',
                  },
                })}
                className={`input-field ${errors.email ? 'error-border' : ''}`}
              />
              {errors.email && <p className="error-message">{errors.email.message}</p>}
            </div>

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                {...register('message', { required: 'Message is required' })}
                className={`input-field ${errors.message ? 'error-border' : ''}`}
              />
              {errors.message && <p className="error-message">{errors.message.message}</p>}
            </div>

            <button type="submit" className="submit-button">Submit</button>
          </form>
        </div>

        <div className="company-info">
          <h2>Get in Touch</h2>
          <p>
            We'd love to hear from you! Feel free to contact us using the form, or reach out to us directly.
          </p>
          <div className="contact-details">
            <div className="contact-item">
              <h3>Our Address</h3>
              <p>1942 Broadway<br />Ste 314 C Boulder, CO 80302</p>
            </div>
            <div className="contact-item">
              <h3>Call Us</h3>
              <p>Phone: +1(719) 403-7561</p>
            </div>
            <div className="contact-item">
              <h3>Email Us</h3>
              <p>Synccarebilling@gmail.com</p>
            </div>
          </div>
        </div>
      </div>

      <br />

      {/* Map Component */}
      <MyMap />
    </div>
  );
};

export default ContactUs;

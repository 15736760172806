import React from 'react';
import styled from 'styled-components';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaEnvelope, FaPhone} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FaLocationDot } from "react-icons/fa6";
const FooterContainer = styled.footer`
  background-color: #f0f4f8; /* Light gray background */
  color: #2c3e50; /* Dark blue-gray text */
  padding: 60px 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-top: 5px solid #3498db; /* Blue accent color */

  @media (max-width: 768px) {
    padding: 40px 15px;
  }
`;

const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterSection = styled.div`
  flex: 1;
  min-width: 250px;
  margin-right: 20px;

  h4 {
    margin-bottom: 20px;
    font-size: 1.4em;
    font-weight: 700;
    color: #3498db; /* Blue accent color */
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
  }

  p, ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul li {
    margin-bottom: 10px;
  }

  ul li a {
    color: #2c3e50; /* Dark blue-gray links */
    text-decoration: none;
    transition: color 0.3s ease;
  }

  ul li a:hover {
    color: #3498db; /* Blue accent color on hover */
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;

  @media (max-width: 600px) {
    gap: 20px;
  }
`;

const SocialIcon = styled.a`
  color: #2c3e50; /* Dark blue-gray icons */
  font-size: 1.6em;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #3498db; /* Blue accent color on hover */
  }
`;

const FooterBottom = styled.div`
  text-align: center;
  margin-top: 40px;
  border-top: 1px solid #dbe2e6; /* Light gray border */
  padding-top: 20px;
  font-size: 0.9em;

  p {
    margin: 0;
  }

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

const ContactDetails = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    display: flex;
    align-items: center;
    font-size: 1.1em;
  }

  p svg {
    margin-right: 10px;
    color: #3498db; /* Blue accent color for icons */
  }
`;

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <h4>Synccare Billing LLC</h4>
          <p>Synccare Billing LLC – Delivering expert medical billing, digital marketing, software development, customer support, insurance verification, and reporting & analytics services for healthcare providers</p>
        </FooterSection>
        <FooterSection>
          <h4>Quick Links</h4>
          <ul>
            <li><Link to="/" onClick={scrollToTop}>Home</Link></li>
            <li><Link to="/about-us" onClick={scrollToTop}>About Us</Link></li>
            <li><Link to="/services" onClick={scrollToTop}>Services</Link></li>
            <li><Link to="/emr-ehr" onClick={scrollToTop}>EMR\EHR</Link></li>
            <li><Link to="/contact-us" onClick={scrollToTop}>Contact</Link></li>
          </ul>
        </FooterSection>
        <FooterSection>
          <h4>Contact Us</h4>
          <ContactDetails>
            <p><FaEnvelope /> <a href="mailto:Synccarebilling@gmail.com">Synccarebilling@gmail.com</a></p>
            <p><FaPhone /> +1(719) 403-7561</p>
            <p><FaLocationDot /> 1942 Broadway, ste 314 C Boulder, CO 80302</p>
          </ContactDetails>
        </FooterSection>
        <FooterSection>
          <h4>Follow Us</h4>
          <SocialIcons>
            <SocialIcon href="https://www.facebook.com/profile.php?id=61565510984611&mibextid=LQQJ4d" aria-label="Facebook"><FaFacebookF /></SocialIcon>
            <SocialIcon href="#" aria-label="Twitter"><FaTwitter /></SocialIcon>
            <SocialIcon href="https://www.linkedin.com/company/synccare-billing-llc/" aria-label="LinkedIn"><FaLinkedinIn /></SocialIcon>
            <SocialIcon href="#" aria-label="Instagram"><FaInstagram /></SocialIcon>
          </SocialIcons>
        </FooterSection>
      </FooterContent>
      <FooterBottom>
        <p>&copy; 2024 SyncCare Billing LLC. All rights reserved.</p>
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Services from './components/service';
import ContactUs from './components/contacts';
import EMR from './components/EMREHR';
import AboutUs from './components/about';
import Navbar from './components/navbar';
import Footer from './components/footer';
import Whatsapp from "./components/whatsapp";
function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/emr-ehr" element={<EMR />} />
          <Route path="/about-us" element={<AboutUs />} />
        </Routes>
        <Whatsapp/>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;

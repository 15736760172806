import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './css/NavBar.css'; // Custom CSS for styling

const Navbar = () => {
    const [isMobile, setIsMobile] = useState(false);

    const toggleMenu = () => {
        setIsMobile(!isMobile);
    };

    return (
        <nav className="navbar">
            <h1 className="navbar-logo">SyncCare Billing LLC</h1>
            {/* Navigation Links */}
            <div className={`nav-links ${isMobile ? 'mobile' : ''}`}>
                <NavLink 
                    to="/" 
                    className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} 
                    onClick={toggleMenu} 
                    end // Ensures Home link is active only for exact `/` route
                >
                    Home
                </NavLink>
                <NavLink 
                    to="/about-us" 
                    className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} 
                    onClick={toggleMenu}
                >
                    About Us
                </NavLink>
                <NavLink 
                    to="/services" 
                    className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} 
                    onClick={toggleMenu}
                >
                    Services
                </NavLink>
                <NavLink 
                    to="/emr-ehr" 
                    className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} 
                    onClick={toggleMenu}
                >
                    EMR / EHR
                </NavLink>
                <NavLink 
                    to="/contact-us" 
                    className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} 
                    onClick={toggleMenu}
                >
                    Contact Us
                </NavLink>
            </div>

            {/* Hamburger Menu Icon */}
            <button className="mobile-menu-icon" onClick={toggleMenu}>
                {isMobile ? (
                    <i className="fas fa-times"></i> 
                ) : (
                    <i className="fas fa-bars"></i>
                )}
            </button>
        </nav>
    );
};

export default Navbar;

import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility';

const MyMap = () => {
  const position = [40.018980, -105.279930];

  return (
    <div>
      

      {/* Google Maps Embed */}
      <div style={{ marginTop: '20px' }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d763.8967316416439!2d-105.28022453044247!3d40.01767352529933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876bec26470e9301%3A0x60b2893c672fc78!2s1942%20Broadway%20%23314c%2C%20Boulder%2C%20CO%2080302%2C%20USA!5e0!3m2!1sen!2s!4v1726636266651!5m2!1sen!2s"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default MyMap;

import React from 'react';

import emr1 from './image/emr1.jpg';
import emr3 from './image/ka.jpg';
import emr4 from './image/emr4.png';
import emr5 from './image/emr5.png';
import emr6 from './image/emr6.png';
import emr7 from './image/emr7.png';
import emr8 from './image/emr8.png';
import emr9 from './image/emr9.png';
import emr10 from './image/emr10.png';
import emr11 from './image/emr11.png';
import mr2 from './image/md.jpg';
import emr14 from './image/emr4.jpg';
import './css/emr.css';

const EMR = () => {
  return (
    <div className="emr-ehr-page">
      <section className='emr-section'>
        <div className='emr-content'>
        <h1>EMR / EHR Solutions</h1>
      <p>We provide top-tier Electronic Medical Record and Electronic Health Record solutions for your practice.</p>

        </div>
      </section>
      <div className="emr-ehr-grid">
        <div className="emr-ehr-item">
          <img src={emr1} alt="eClinicalWorks" />
          <h4>eClinicalWorks</h4>
        </div>
        <div className="emr-ehr-item">
          <img src={mr2} alt="Collaborate MD" />
          <h4>Collaborate MD</h4>
        </div>
        <div className="emr-ehr-item">
          <img src={emr3} alt="Kareo" />
          <h4>Kareo</h4>
        </div>
        <div className="emr-ehr-item">
          <img src={emr14} alt="Kareo" />
          <h4>Office Ally</h4>
        </div>
        <div className="emr-ehr-item">
          <img src={emr4} alt="EMR Solution 1" />
          <h4>Simple Practice</h4>
        </div>
        <div className="emr-ehr-item">
          <img src={emr5} alt="EMR Solution 2" />
          <h4>Practice Fusion</h4>
        </div>
        <div className="emr-ehr-item">
          <img src={emr6} alt="EMR Solution 3" />
          <h4>Practice EHR</h4>
        </div>
        <div className="emr-ehr-item">
          <img src={emr7} alt="EMR Solution 3" />
          <h4>SequelMed</h4>
        </div>
        <div className="emr-ehr-item">
          <img src={emr8} alt="EMR Solution 3" />
          <h4>Brightree</h4>
        </div>
        <div className="emr-ehr-item">
          <img src={emr9} alt="EMR Solution 3" />
          <h4>Optimantra</h4>
        </div>
        <div className="emr-ehr-item">
          <img src={emr10} alt="EMR Solution 3" />
          <h4>TheraBill</h4>
        </div>
        <div className="emr-ehr-item">
          <img src={emr11} alt="EMR Solution 3" />
          <h4>Nextgen</h4>
        </div>
        {/* Continue adding more items as needed */}
      </div>
      <br/>
    </div>
  );
};

export default EMR;

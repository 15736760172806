import React from 'react';
import { useEffect } from 'react';
import './css/home.css';
import image1 from "./image/Slider-1.jpg";
import image2 from "./image/image6.png";
import image4 from "./image/image7.png";
import image5 from "./image/rep.jpg";
import image10 from "./image/in.jpg";
import image3 from "./image/image9.png";
import image6 from "./image/cust.jpg";
import image7 from "./image/d.jpg";
import image8 from "./image/med.jpg";
import image9 from "./image/s.jpg";
import { Link } from 'react-router-dom';

const Home = () => {
 

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className="home-page">
      {/* Hero Section */}
      <section className="hero-section">
  <div className="hero-content">
    <h1>Welcome to Synccare Billing LLC</h1>
    <p>
      At SyncCare Billing LLC, we understand that managing medical billing and coding can be a complex and time-consuming task. That’s why we’re dedicated to providing healthcare providers with seamless, efficient, and reliable billing solutions, so you can focus on what matters most—your patients.
    </p>
    
  </div>
</section>



      {/* About Section */}
      <section id="about" className="about-section">
  <h2>About Us</h2>
  <p>We are a forward-thinking company, dedicated to building impactful solutions for our clients. With a focus on innovation and exceptional support, we aim to help businesses thrive in a fast-changing world. Our collaboration with SyncCare reflects our commitment to integrating advanced technology and providing top-notch service.</p>
  <div className="about-features">
    <div className="feature-item">
      <img src={image2} alt="Innovation" />
      <h3>Innovation</h3>
      <p>At the forefront of cutting-edge technology with SyncCare integration.</p>
    </div>
    <div className="feature-item">
      <img src={image3} alt="Excellence" />
      <h3>Excellence</h3>
      <p>Committed to delivering outstanding results through advanced SyncCare solutions.</p>
    </div>
    <div className="feature-item">
      <img src={image4} alt="Support" />
      <h3>Support</h3>
      <p>We are here to support you at every step with SyncCare’s comprehensive resources.</p>
    </div>
  </div>
</section>


      {/* Services Section */}
      <section className="services-section">
  <h2>Our Services</h2>
  <div className="services-list">
  <div className="service-item">
      <img src={image8} alt="Medical Billing" />
      <h3>Medical Billing</h3>
      <p>Leverage SyncCare’s advanced billing systems to ensure accurate and efficient medical billing services.</p>
    </div>
    <div className="service-item">
      <img src={image7} alt="Digital Marketing" />
      <h3>Digital Marketing</h3>
      <p>Utilize SyncCare’s data-driven insights to enhance your marketing strategies and reach your target audience effectively.</p>
    </div>
    <div className="service-item">
      <img src={image9} alt="Software Development" />
      <h3>Software Development</h3>
      <p>Develop customized solutions integrated with SyncCare’s platform to streamline operations and boost efficiency.</p>
    </div>
   
    <div className="service-item">
      <img src={image6} alt="Customer Support" />
      <h3>Customer Support</h3>
      <p>Enhance customer satisfaction with SyncCare’s support tools, ensuring timely and effective assistance.</p>
    </div>
    <div className="service-item">
      <img src={image10} alt="Customer Support" />
      <h3>Insurance Verification</h3>
      <p>We verify patient insurance details to prevent claim rejections and delays.</p>
    </div>
    <div className="service-item">
      <img src={image5} alt="Customer Support" />
      <h3>Reporting and Analytics</h3>
      <p>We offer detailed financial reports and insights to help you make informed decisions and improve your practice’s financial performance.</p>
    </div>
  </div>
</section>


      {/* Testimonials Section */}
      <section className="testimonials-section">
  <h2>What Our Clients Say</h2>
  <div className="testimonials-list">
    <div className="testimonial-item">
      <p>"SyncCare provided us with invaluable insights and solutions. Their data-driven approach helped us streamline our processes and improve efficiency."</p>
      <h4>- Dr. Sarah Johnson</h4>
    </div>
    <div className="testimonial-item">
      <p>"The SyncCare team went above and beyond to understand our needs. Their software solutions have transformed the way we operate, and our productivity has never been higher."</p>
      <h4>- Michael Davis</h4>
    </div>
    <div className="testimonial-item">
      <p>"With SyncCare’s customized healthcare solutions, we’ve seen a significant improvement in our billing accuracy and patient satisfaction."</p>
      <h4>- Dr. Emily Lee</h4>
    </div>
    <div className="testimonial-item">
      <p>"SyncCare’s innovative technology allowed us to enhance our customer service operations, improving response times and overall customer satisfaction."</p>
      <h4>- John Peterson</h4>
    </div>
  </div>
</section>


      {/* Contact Section */}
      <section className="contact-section1">
        <h2>Get In Touch</h2>
        <p>Ready to take the next step? Contact us to see how we can help you achieve your goals.</p>
        <Link onClick={scrollToTop}
  to="/contact-us" 
  style={{
    backgroundColor: '#4CAF50', 
    color: 'white', 
    padding: '10px 20px', 
    textAlign: 'center', 
    textDecoration: 'none', 
    display: 'inline-block', 
    borderRadius: '5px',
    fontSize: '16px'
  }}
>
  Contact Us
</Link>

      </section>
    </div>
  );
};

export default Home;

import React from 'react';
import './css/Whatsapp.css'; // Import the CSS file

const Whatsapp = () => {
  const handleClick = () => {
    // Redirect to WhatsApp chat
    window.open('https://wa.me/+17194037561', '_blank'); // Replace with your WhatsApp number
  };

  return (
    <div className="whatsapp-container" onClick={handleClick}>
      <img 
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" // Path to your custom WhatsApp icon image
        alt="WhatsApp Icon" 
        className="whatsapp-icon" 
      />
      
    </div>
  );
};

export default Whatsapp;
